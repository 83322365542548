/**
 * @module ServiceCheckForm
 * @description Service Check Form
 */
import {
  useState, useRef, useEffect, useContext
} from 'react';
import debounce from 'lodash/debounce';
import { usePredictiveSearch } from '@/hooks/usePredictiveSearch';
import { isValidAddressInput } from '@/js/utils/validators';
import { stringify } from '@/js/utils/filters';
import { trackEvent } from '@/js/helpers/tracking';
import { v4 as uuidv4 } from 'uuid';
import styles from './index.module.css';
import { FuelContext } from '@/providers/fuel-provider';
import { persistData } from '@/js/utils/session-storage';
import useMediaQuery from '@/hooks/useMediaQuery';

/**
 * @function Component
 * @description
 */
const Component = ( { ...props } ) => {
  const {
    theme,
    isHero,
    reducedCta,
    reducedPlaceholderText,
    enterGDT = false,
    enableNetflixPassthrough = false,
    position = '',
    slideNumber
  } = props;

  const carouselPosition = slideNumber ? `- SLIDE ${ slideNumber }` : '';

  const formContext = {
    formType: 'SERVICE CHECK - SINGLE LINE',
    formName: 'SERVICE CHECK - SINGLE LINE',
    formPosition: `${ position } ${ carouselPosition }`
  };

  const [ addressValid, setAddressValid ] = useState( false );
  const [ addressError, setAddressError ] = useState( false );
  const [ suggestions, setSuggestions ] = useState( [ ] );
  const [ addressObject, setAddressObject ] = useState( { } );
  const [ formStarted, setFormStarted ] = useState( false );
  const [ addressKey, setAddressKey ] = useState( false );
  const [ placeholderText, setPlaceholderText ] = useState( 'Enter your address.' );
  const [ id, setId ] = useState();
  const isXLDesktop = useMediaQuery( '(min-width: 1440px)' );
  const isDesktop = useMediaQuery( '(min-width: 1280px)' );
  const addressField = useRef( null );
  const honeyPotField = useRef( null );

  const predictive = usePredictiveSearch( { addressField, honeyPotField }, setSuggestions, formContext, enableNetflixPassthrough );

  const { setFormInputted, setPauseCarousel } = useContext( FuelContext );

  /**
   * @function setSuggestionValue
   * @description Set suggestion value on address and zip
   */
  const setSuggestionValue = async ( event ) => {
    const { address, addressData } = await predictive.setSuggestionValue( event );

    setAddressValid( isValidAddressInput( address ) );
    setAddressError( ! isValidAddressInput( address ) );
    setAddressObject( addressData );
    setAddressKey( true );
  };

  /**
   * @function fetchAddress
   * @description Fetch our address suggestions
   */
  const fetchAddress = async ( event ) => {
    if ( ! formStarted ) {
      trackEvent( { action: 'formStarted', data: formContext } );
      setFormStarted( true );
    }

    predictive.fetchAddress( event );
    setFormInputted( true );
  };

  /**
   * @function handleResize
   * @description Update placeholder text if viewport changes
   */
  const handleResize = () => {
    if ( isDesktop && ! reducedPlaceholderText ) {
      return setPlaceholderText( 'Enter your address to view plans in your area.' );
    }

    setPlaceholderText( 'Enter your address.' );
  };

  useEffect( () => {
    handleResize();
    document.addEventListener( 'redventures/resize_executed', handleResize );
  }, [ isDesktop, reducedPlaceholderText ] );

  /**
   * @function useEffect
   * @description Set our placeholder, then attach viewport resize event
   */
  useEffect( () => {
    setId( uuidv4() );
    persistData( 'netflixProduct', false );
  }, [] );

  /**
   * @function useEffect
   * @description Set pauseCarousel to true when address check is active
   */
  const handleAddressCheckActive = () => {
    setPauseCarousel( true );
  };

  /**
   * @function useEffect
   * @description Set pauseCarousel to false when address check is inactive
   */
  const handleAddressCheckInactive = () => {
    setPauseCarousel( false );
  };

  return (
    <div className={ `${ styles.component } ${ theme && styles[ `component__${ theme }` ] } ${ isHero ? styles.component__isHero : '' }` }>
      <form name="service-check-form" className={ styles.component__form } autoComplete="off"
        onSubmit={ ( event ) => predictive.handleSubmit( event, enterGDT ) } onMouseEnter={ handleAddressCheckActive }
        onFocus={ handleAddressCheckActive } onMouseLeave={ handleAddressCheckInactive } onBlur={ handleAddressCheckInactive }
        data-address-object={ stringify( addressObject ) } data-cypress="service-check-form">
        <span className={ `${ styles.component__icon } icon icon-location-marker2` } />
        <div className={ `field ${ styles.component__formField }` }>
          <label className="screen-reader-text" htmlFor={ `street-address-${ id }` }>Street Address</label>
          <input
            ref={ addressField }
            type="text"
            name="street-address"
            id={ `street-address-${ id }` }
            data-test-id="street-address"
            onInput={ debounce( fetchAddress, 500 ) /* fetchAddress */ }
            className="input"
            placeholder={ placeholderText }
            data-payload={ stringify( {
              fieldName: 'street',
              fieldType: 'street',
              fieldValue: 'STREET ADDRESS'
            } ) }/>
          <span className={ `field-error ${ addressError === true ? '--active' : '' }` }>Invalid Address</span>
          <div className={ `${ styles.component__suggestions } ${ suggestions && suggestions.length > 0 ? styles.component__suggestionsActive : '' }` }>
            { suggestions && suggestions.length > 0 &&
              <>
                <ul className={ styles.component__suggestionsList }>
                  { suggestions.map( ( suggestion, index ) => (
                      <li key={ index }>
                        <button
                          type="button"
                          role="button"
                          data-address={ suggestion.value }
                          data-zip={ suggestion.zipCode }
                          data-object={ stringify( suggestion ) }
                          onClick={ setSuggestionValue }
                        >{ suggestion.value }</button>
                      </li>
                  ) ) }
                </ul>
                <div className={ styles.component__manual }>
                  <button
                    type="button"
                    role="button"
                    onClick={ () => setSuggestions( [ ] ) }
                  >Close address suggestions</button>
                </div>
              </>
            }
          </div>{ /* <!-- .component__suggestions --> */ }
        </div>{ /* <!-- .field --> */ }

        <div className="field" data-hidden-input>
          <label htmlFor={ `hidden-input-${ id }` }>Hidden Input</label>
          <input
            ref={ honeyPotField }
            type="text"
            name="hidden-input"
            id={ `hidden-input-${ id }` }
            onInput={ predictive.handleInput }
            data-payload={ stringify( {
              fieldName: 'honeypot',
              fieldType: 'honeypot',
              fieldValue: 'HONEYPOT'
            } ) }
          />
        </div>{ /* <!-- .field --> */ }

        <button
          type="submit"
          className="btn"
          disabled={ ! addressValid || ! addressKey }
          aria-disabled={ ! addressValid || ! addressKey }
          role="button"
        >{ reducedCta ? 'Go' : ( ( isXLDesktop ) ? 'Check availability' : 'Go' ) }</button>
      </form>
    </div>
  );
};

export default Component;
